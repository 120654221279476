import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import { InvoiceList } from '../../components/invoice-list/invoice-list'


class Invoices extends Component {

    constructor(props) {
        super(props)

        this.state = {
            invoices: [],
            invoices_loading: false,
        }

        this.downloadInvoicePdf = this.downloadInvoicePdf.bind(this);
    }

    componentDidMount() {
        this.getAllInvoices();
    }

    getAllInvoices() {
        this.setState({ subscriptions_loading: true }, () => {
            request('GET', '/account/invoices', null, true).then(res => {
                this.setState({
                    invoices_loading: false,
                    invoices: res.data.invoices,
                })
            }).catch(err => {
                Swal.fire(
                    'Error',
                    "Something has gone wrong, invoices are not available. Please try again later",
                    'error'
                )
            })
        });
    }

    downloadInvoicePdf = (id) => {
        this.setState({ subscriptions_loading: true }, () => {
            request('GET', '/account/invoices/'+id+"/pdf", null, true).then(res => {
                window.open(res.data.url, "_blank", 'noopener,noreferrer')
            }).catch(err => {
                Swal.fire(
                    'Error',
                    "Something has gone wrong, invoice is not available for download. Please try again later",
                    'error'
                )
            })
        });
    }

    render() {

        if (this.state.invoices_loading) {
            return (
                <div>
                    Is Loading...
                </div>
            )
        }

        if (!this.state.invoices) {
            return (
                <div>
                    No active subscriptions yet
                </div>
            )
        }

        return (
            <div>
                <InvoiceList invoices={this.state.invoices} downloadInvoicePdf={this.downloadInvoicePdf} />
            </div>
        )
    }
}

export default Invoices;
