import Axios from 'axios';
import cookies from 'react-cookies';

const API_URL = process.env.REACT_APP_API_URL;

export const request = (method, url, data = null, isNeedAuthorization = false) => {
    if (data) {
        return Axios.request({
            method: method,
            url: API_URL + url,
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': isNeedAuthorization ? 'Bearer ' + getToken() : null
            }
        })
    } else {
        return Axios.request({
            method: method,
            url: API_URL + url,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': isNeedAuthorization ? 'Bearer ' + getToken() : null
            }
        })
    }
}

export const getToken = () => {
    const user = getUser();
    return user;
}
export const getUser = () => {
    return cookies.load('user-token');
}