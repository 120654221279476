import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import { SubscriptionList } from '../../components/subscription-list/subscription-list'


class Subscriptions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            subscriptions: [],
            subscriptions_loading: false,
        }
    }

    componentDidMount() {
        this.getActiveSubscriptions();
    }

    getActiveSubscriptions() {
        this.setState({ subscriptions_loading: true }, () => {
            request('GET', '/subscriptions', null, true).then(res => {
                this.setState({
                    subscriptions_loading: false,
                    subscriptions: res.data.subscriptions,
                })
            }).catch(err => {
                Swal.fire(
                    'Error',
                    "Something has gone wrong, available subscriptions are not available. Please try again later",
                    'error'
                )
            })
        });
    }

    render() {

        if (this.state.subscriptions_loading) {
            return (
                <div>
                    Is Loading...
                </div>
            )
        }

        if (!this.state.subscriptions) {
            return (
                <div>
                    No active subscriptions yet
                </div>
            )
        }

        return (
            <div>
                <SubscriptionList subscriptions={this.state.subscriptions} />
            </div>
        )
    }
}

export default Subscriptions;
