import React, { Component } from 'react'
import { request } from '../../constants/constants';

class myaccount extends Component {
    constructor(props) {
        super(props)

        this.updatePaymentMethods = this.updatePaymentMethods.bind(this);
    }

    updatePaymentMethods = () => {
        this.setState({ isLoading: true }, () => {
            request('PUT', '/account/paymentmethods', { redirectUrl: window.location.href }, true).then((result) => {
                window.location.href = result.data.url;
            })
        });
    }

    render() {
        return (
            <div className="container mt-5">
                <div class="row justify-content-center">
                    <button onClick={this.updatePaymentMethods}>Update payment methods</button>
                </div>
            </div>
        )
    }
}

export default myaccount;
