import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

export function SubscriptionItemPrice(props) {
    let price = props.item.price/100;
    let currencySign = "$";
    switch (props.item.currency) {
        case "AUD":
        case "USD":
            currencySign = "$";
            break;
        case "GBP":
            currencySign = "£"
            break;
        default:
            currencySign = "$";
            break;
    }
    return (
        <div className="col mt-3 shadow p-3 mb-2 bg-white rounded">
            <Card style={{border: 'none'}}>
            {/* <Card style={{ width: '18rem', flex: 1}}> */}
            <Card.Body>
            <Card.Title>{props.item.name}</Card.Title>
            <ListGroup variant="flush">
                <ListGroup.Item>{currencySign}{price} {props.item.currency} per {props.item.cycle} </ListGroup.Item>
            </ListGroup>
            <Button variant="primary" onClick={() => {props.onSelect(props.item.id)}}>{(props.item.trial_description ?  "Start " +props.item.trial_description + " free trial" : "Sign Up")}</Button>
            </Card.Body>
            </Card>
        </div>

        
    )
}