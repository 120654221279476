import React, { Component } from 'react';
import LogoImg from '../../public/images/luxietech-full.png';
import emailSvg from '../../public/svg/email.svg'
import passwordSvg from '../../public/svg/password.svg';
import './login.scss';
import { Link, Redirect } from 'react-router-dom';
import { request } from '../../constants/constants';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import { InputComponent } from '../../components/input-component/input-component';
import UserContext from '../../contexts/user';

class Login extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {

            redirect: false,
            redirectUrl: Cookies.get('login_return')

        }

        Cookies.remove('login_url');
    }

    componentDidMount() {
        if (this.context.user) {
            this.setState({ redirect: true })
        }
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    validateLogin(username, password) {
        return username && password;
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        let email = e.target.email.value;
        let password = e.target.password.value;

        if (this.validateLogin(email, password)) {
            request('POST', '/login', {email:email, password:password}, false).then(res => {
                this.setState({isLoading: false});
                Cookies.set('user-token', res.data.token)
                Swal.fire({title: "Success", message:res.data.message, type:"success", timer:2000}).then(() => {
                    this.props.onLogin(res.data.token);
                    this.setState({redirect: true});
                })
            }).catch(err => {
                this.setState({
                    isLoading: false
                })
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
            })
        } else {
            this.setState({isLoading: false});
            Swal.fire(
                'Error',
                "Please fix the validation errors",
                'error'
            )
        }
    }

    render() {
        if (this.state.redirect) {
            if (this.state.redirectUrl) {
                window.location.href = this.state.redirectUrl
            } else {
                return <Redirect to="/dashboard" />
            }
        }

        return (
            <div className="login-container text-center">
                <div className="logo-container">
                    <img src={LogoImg} alt={"Luxie Tech Logo"} />
                </div>
                <div className="form-container p-5">
                    <h2 className="mb-4">Login</h2>
                    <form onSubmit={this.handleSubmit}>

                        <InputComponent onChange={this.handleChange} name="email" type="text" svgImage={emailSvg} placeholder="Email"/>
                        <InputComponent onChange={this.handleChange} name="password" type="password" svgImage={passwordSvg} placeholder="Password"/>
                    
                        <div className="forgot-keepme">
                            <div className="row no-gutters">
                                <div className="col-5">
                                    <div className="login-link">
                                        <Link to={"/signup" + (this.state.redirectUrl ? `?return=${this.state.redirectUrl}` : '')}>Don't have an account? Sign up here</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="action">
                            <button disabled={this.state.isLoading} type="submit" name="login" className="btn btn-block btn-primary">
                                {
                                    this.state.isLoading ? (
                                        <div>
                                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                            Loading...
                                        </div>
                                    ) : 'Sign in'
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Login;