export function InputComponent(props) {
    return (

        <div className="form-group">
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <span className="icon icon-pass">
                            <img src={props.svgImage} className="imgsvg" alt={props.name} />
                        </span>
                    </span>
                </div>
                <input onChange={props.handleChange} name={props.name}  type={props.type} className="form-control" placeholder={props.placeholder} />
            </div>
        </div>
    )
}