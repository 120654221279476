import { Navbar, NavDropdown, Nav, Container } from "react-bootstrap";
import LogoImg from '../../public/images/luxietech-full.png';

export function Header(props) {
    return (
        <Navbar className="mr-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} >
            <Container>
                <img src={LogoImg} width="320" className="d-inline-block align-top" alt="LuxieTech logo" />
                <Nav.Link href="/dashboard">Home</Nav.Link>
                <NavDropdown title="Subscriptions" >
                    {/* <NavDropdown.Item href="/subscriptions/select-plan">New</NavDropdown.Item> */}
                    <NavDropdown.Item href="/subscriptions">View</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/invoices">Invoices</Nav.Link>
                <Nav.Link href="/myaccount">My Account</Nav.Link>
                <NavDropdown title="Profile" >
                    <NavDropdown.Item onClick={props.logout}>Logout</NavDropdown.Item>
                </NavDropdown>
            </Container>
        </Navbar>
        
    )
}