import React, { Component } from 'react';
import LogoImg from '../../public/images/luxietech-full.png';
import emailSvg from '../../public/svg/email.svg'
import passwordSvg from '../../public/svg/password.svg';
import './register.scss';
import { Link, Redirect } from 'react-router-dom';
import { request } from '../../constants/constants';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import validator from 'validator'
import { InputComponent } from '../../components/input-component/input-component';

class Register extends Component {

    constructor(props) {
        super(props);
        
        const query_params = new URLSearchParams(window.location.search);

        this.state = {

            redirect: false,
            redirectUrl: query_params.get('return'),

            company_name: query_params.get('company_name'),
            first_name: query_params.get('first_name'),
            last_name: query_params.get('last_name'),
            email: query_params.get('email'),
            password: "",
            password_confirmation: "",
        }

        this.handleChange = this.handleChange.bind(this);
        this.onSignup = this.onSignup.bind(this);
    }
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    validateRegistration(email, password, password_confirmation, first_name, last_name, company_name) {
        return (validator.isEmail(email) && password === password_confirmation && password.length > 8 && first_name && last_name && company_name);
    }

    onSignup(event) {
        this.setState({isLoading: true});
        event.preventDefault();
        let email = event.target.email.value;
        let password = event.target.password.value;
        let password_confirmation =event.target.password_confirmation.value;
        let first_name = event.target.first_name.value;
        let last_name = event.target.last_name.value;
        let company_name = event.target.company_name.value;

        if (this.validateRegistration(email, password, password_confirmation, first_name, last_name, company_name)) {
            request('POST', '/signup', {email: email, password:password, password_confirmation:password_confirmation, first_name: first_name, last_name: last_name, company_name: company_name}).then(res => {
                this.setState({isLoading: false});
                if (res.data.token) {
                    Cookies.set('user-token', res.data.token)
                    Swal.fire({title: "Success", message:res.data.message, type:"success", timer:2000}).then(() => {
                        this.setState({redirect: true});
                    })
                } else {
                    Swal.fire(
                        'Success',
                        res.data.message,
                        'success'
                    )
                }
            }).catch(error => {
                this.setState({isLoading: false});
                Swal.fire(
                    'Error',
                    error.response.data.error,
                    'error'
                )    
            });
        } else {
            this.setState({isLoading: false});
            Swal.fire(
                'Error',
                "Please fix the validation errors",
                'error'
            )
        }
    }

    render() {

        if (this.state.redirect) {
            if (this.state.redirectUrl) {
                return <Redirect to={"/"+this.state.redirectUrl} />
            } else {
                return <Redirect push to="/dashboard" />
            }
        }

        return (
            <div className="login-container text-center">
                <div className="logo-container">
                    <img src={LogoImg} alt={"Luxie Tech Logo"} />
                </div>
                <div className="form-container p-5">
                    <h2 className="mb-4">Register</h2>
                    <form onSubmit={this.onSignup}>

                        <InputComponent onChange={this.handleChange} name="company_name" type="text" svgImage={emailSvg} placeholder="Company Name"/>
                        <InputComponent onChange={this.handleChange} name="first_name" type="text" svgImage={emailSvg} placeholder="First Name"/>
                        <InputComponent onChange={this.handleChange} name="last_name" type="text" svgImage={emailSvg} placeholder="Last Name"/>
                        <InputComponent onChange={this.handleChange} name="email" type="text" svgImage={emailSvg} placeholder="Email"/>
                        <InputComponent onChange={this.handleChange} name="password" type="password" svgImage={passwordSvg} placeholder="Password"/>
                        <InputComponent onChange={this.handleChange} name="password_confirmation" type="password" svgImage={passwordSvg} placeholder="Password Confirmation"/>
                    
                        <div className="forgot-keepme">
                            <div className="row no-gutters">
                                <div className="col-5">
                                    <div className="login-link">
                                        <Link to={"/login"+(this.state.query_params ? `?return=${window.location.href}` : '')}>Already have an account? Sign in here</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="action">
                            <button disabled={this.state.isLoading} type="submit" name="signup" className="btn btn-block btn-primary">
                                        {
                                            this.state.isLoading ? (
                                                <div>
                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" />
                                                    Loading...
                                                </div>
                                            ) : 'Sign up'
                                        }
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Register;