import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import {SubscriptionItem} from '../../components/subscription-item/subscription-item'
import './select-plan.scss';
import { Redirect } from 'react-router-dom';

class SelectPlan extends Component {

    constructor(props) {
        super(props);

        const query_params = new URLSearchParams(window.location.search);

        this.state = {
            plans_loading: false,
            items: [],
            selected_item: query_params.get('item'),

            redirect: "",
        }

        this.getPlans = this.getPlans.bind(this);
        this.onItemSelect = this.onItemSelect.bind(this);
    }

    componentDidMount() {
        if (this.state.pre_selected_item) {

        } else {
            this.getPlans();
        }
    }

    getPlans() {
        this.setState({plans_loading:true}, () => {
            request('GET', '/subscriptions/items', null, true).then(res => {
                this.setState({
                    plans_loading: false,
                    items: res.data,
                })
            }).catch(err => {
                Swal.fire(
                    'Error',
                    "Something has gone wrong, available subscriptions are not available. Please try again later",
                    'error'
                )
            })
        });
    }


    onItemSelect(planId) {
        this.setState({redirect: "/subscriptions/select-plan/"+planId});
    }

    render() {
        if (this.state.redirect) {
            return <Redirect push to={this.state.redirect} />
        }

        let items = null;
        if (this.state.items) {
            items = this.state.items.map(item => {
                return <SubscriptionItem item={item} onSubmit={this.onItemSelect} />
            })
        }

        return ( 
            
            <div className="login-container text-center">
                {this.state.plans_loading &&  <p>Loading plans...</p>}
                <div className="container">
                    <div className="mt-5 row">
                        {/* <div className='col-4' style={{display: 'flex', flexDirection: 'row'}} > */}
                            {!this.state.plans_loading && items}
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectPlan;