import React from 'react';

import { useTable } from 'react-table';


export function InvoiceList(props) {
    const columns = React.useMemo(() => [
        {
            Header: 'Invoice Number',
            accessor: 'invoice_number',
        },
        {
            Header: 'Invoice Total',
            accessor: 'invoice_total',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Invoice Date',
            accessor: 'date',
        },
        {
            Header: '',
            accessor: 'download_link',
        }
    ], [])

    const invoices = props.invoices;
    const downloadInvoicePdf = props.downloadInvoicePdf;
    const data = React.useMemo(() => invoices.map(inv => {
        let currencySign = "$";
        const invoiceStatuses = { 'paid': 'Paid', 'posted': "Posted", 'payment_due': "Overdue", 'note_paid': "Overdue", "voided": "Voided", "pending": "Draft" };
        switch (inv.currency_code) {
            case "AUD":
            case "USD":
                currencySign = "$";
                break;
            case "GBP":
                currencySign = "£"
                break;
            default:
                currencySign = "$";
                break;
        }
        
        return {
            invoice_number: inv.id,
            invoice_total: currencySign + '' + (inv.total) / 100 + " " + inv.currency_code,
            status: invoiceStatuses[inv.status],
            date: new Date(inv.date * 1000).toLocaleDateString(),
            download_link: <button onClick={() => downloadInvoicePdf(inv.id)}>Download</button>,
            payment_link: inv.amount_due > 0 ? <a href={"/subscriptions/" + inv.id}><button>Pay Now</button></a> : null
        }
    }),[invoices, downloadInvoicePdf])

    const tableInstance = useTable({ columns, data });

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

    return (
        <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                style={{
                                    borderBottom: 'solid 3px red',
                                    background: 'aliceblue',
                                    color: 'black',
                                    fontWeight: 'bold',
                                }}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                            padding: '10px',
                                            border: 'solid 1px gray',
                                            background: 'papayawhip',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )

}