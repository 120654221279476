import React from 'react';
import { useTable } from 'react-table';

export function SubscriptionList(props) {
    const columns = React.useMemo( () => [
        {
            Header:'Subscription Name',
            accessor: 'subscription_name',
        },
        {
            Header: 'Price',
            accessor: 'subscription_price',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Next Payment',
            accessor: 'next_payment',
        },
        {
            Header: '',
            accessor: 'manage_link',
        }
    ], [])
    const subscriptions = props.subscriptions
    const data = React.useMemo(() => subscriptions.map(sub => {
        let currencySign = "$";
        switch (sub.currency_code) {
            case "AUD":
            case "USD":
                currencySign = "$";
                break;
            case "GBP":
                currencySign = "£"
                break;
            default:
                currencySign= "$";
                break;
        }
        const subscriptionStatuses = { 'cancelled': 'Cancelled', 'in_trial':"Trialling", 'active':"Active", 'future':"Future", "non_renewing":"Not renewing"};
        return {
            subscription_name: sub.subscription_name,
            subscription_price: currencySign + '' + (sub.subscription_price) / 100 + " " + sub.currency_code + " per "+sub.billing_period_unit,
            status: subscriptionStatuses[sub.status],
            next_payment: new Date(sub.next_billing_at * 1000).toLocaleDateString(),
            manage_link: <a href={"/subscriptions/" + sub.id }><button>Manage</button></a>
        }
    }), [subscriptions] )

    const tableInstance = useTable({columns, data});

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance

    return (
        <table {...getTableProps()} style={{ border: 'solid 1px blue' }}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                                style={{
                                    borderBottom: 'solid 3px red',
                                    background: 'aliceblue',
                                    color: 'black',
                                    fontWeight: 'bold',
                                }}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                            padding: '10px',
                                            border: 'solid 1px gray',
                                            background: 'papayawhip',
                                        }}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )

}