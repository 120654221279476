import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import { SubscriptionActions } from '../../components/subscription-actions/subscription-actions'


class SubscriptionDetail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            subscription: [],
            isLoading: false,
            subscriptionId: this.props.match.params.subscriptionId,
        }

        // this.pauseSubscription = this.pauseSubscription.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.restartSubscription = this.restartSubscription.bind(this);
        this.restartNonRenewingSubscription = this.restartNonRenewingSubscription.bind(this);
    }

    componentDidMount() {
        this.getSubscription();
    }

    getSubscription() {
        this.setState({ isLoading: true }, () => {
            request('GET', '/subscriptions/'+this.state.subscriptionId, null, true).then(res => {
                this.setState({
                    isLoading: false,
                    subscription: res.data.subscription,
                })
            }).catch(err => {
                Swal.fire(
                    'Error',
                    "Subscription not found",
                    'error'
                ).then(res => {
                    window.location.href = "/subscriptions"
                })
            })
        });
    }

    // pauseSubscription = (id) => {
    //     this.setState({ isLoading: true }, () => {
    //         request('PUT', '/subscriptions/' + id + '/pause', null, true).then(res => {
    //             this.getSubscription();
    //         }).catch(err => {
    //             Swal.fire(
    //                 'Error',
    //                 "An error has occured, please try again",
    //                 'error'
    //             )
    //         })
    //     });
    // }

    cancelSubscription = (id) => {
        this.setState({ isLoading: true }, () => {
            request('PUT', '/subscriptions/' + id +'/cancel', null, true).then(res => {
                this.getSubscription();
            }).catch(err => {
                Swal.fire(
                    'Error',
                    "An error has occured, please try again",
                    'error'
                )
            })
        });
    }

    restartSubscription = (id) => {
        this.setState({ isLoading: true }, () => {
            request('PUT', '/subscriptions/' + id + '/restart', { redirectUrl: window.location.href}, true).then(res => {
                this.getSubscription();
            }).catch(err => {

                if (err.response) {
                    //if the error was no payment method
                    if (err.response.status === 402) {
                        Swal.fire({
                            title: 'Payment Method Required',
                            showCancelButton: true,
                            confirmButtonText: 'Add payment method',
                        }).then((result) => {
                            if (result.value) {
                                window.location.href = err.response.data.url;
                            } else {
                                Swal.fire('Unable to activate subscription', '', 'error').then(res => {
                                    this.getSubscription();
                                })
                            }
                        })
                    }
                } else {
                
                    Swal.fire(
                        'Error',
                        "An error has occured, please try again",
                        'error'
                    ).then(res => {
                        this.getSubscription();
                    })
                }
            })
        });
    }

    restartNonRenewingSubscription = (id) => {

    }

    render() {

        if (this.state.isLoading) {
            return (
                <div>
                    Is Loading...
                </div>
            )
        }

        if (!this.state.subscription) {
            return (
                <div>
                    No subscription found
                </div>
            )
        }

        

        return (
            <div>
                <p>Billing Period: {this.state.subscription.billing_period_unit}</p>
                <p>First Name: {this.state.subscription.customer_first_name}</p>
                <p>Last Name: {this.state.subscription.customer_last_name}</p>
                <p>Status: {this.state.subscription.status}</p>
                <p>Subscription Name: {this.state.subscription.subscription_name}</p>
                <p>Subscription Price: {this.state.subscription.subscription_price}</p>
                <SubscriptionActions subscription={this.state.subscription} restartSubscription={this.restartSubscription} restartNonRenewningSubscription={this.restartNonRenewingSubscription} pauseSubscription={this.pauseSubscription} cancelSubscription={this.cancelSubscription} />
            </div>


        )
    }
}

export default SubscriptionDetail;
