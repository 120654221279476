import React, { Component } from 'react';
import Cookies from 'js-cookie';

class CheckoutSuccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect_url: Cookies.get('app-redirect-url')
        }
    }

    render() {
        if (this.state.redirect_url && this.state.redirect_url !== 'undefined') {
            return window.location.href = this.state.redirect_url
        }

        return (

            <div className="login-container text-center">
                <h3>Thank you for signing up</h3>
                {/* <p> We should display information to get started</p> */}
            </div>
        );
    }
}

export default CheckoutSuccess;