import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

export function SubscriptionItem(props) {
    return (
        // <Card style={{ width: '50rem', flex: 1, margin: 20}}>
        <div className="col-4 mt-3 shadow p-3 mb-2 bg-white rounded">
            <Card style={{border: 'none'}}>
            <Card.Body>
            <Card.Title>{props.item.name}</Card.Title>
            <ListGroup variant="flush">
                <ListGroup.Item>{props.item.description}</ListGroup.Item>
            </ListGroup>
            </Card.Body>
            <Card.Footer style={{backgroundColor: 'White', border: 'none', marginBottom: '10px'}}>
                <Button variant="primary" onClick={() => {props.onSubmit(props.item.id)}}>Select</Button>
            </Card.Footer>
            </Card>
        </div>
    )
}