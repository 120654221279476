import React from 'react';


export function SubscriptionActions(props) {

    const status = props.subscription.status;
    const id = props.subscription.id

    return (
        <div>
            {/* {(status === 'active') && (
                <div>
                    <button onClick={() => props.pauseSubscription(id)} >Pause</button>
                </div>
            )} */}
            {(status === 'in_trial' || status === 'active')  && (
                <div>
                    <button onClick={() => props.cancelSubscription(id)} >Cancel</button>
                </div>
            )}
            {(status === 'cancelled' || status === 'paused') && (
                <div>
                    <button onClick={() => props.restartSubscription(id)} >Restart</button>
                </div>
            )}
            {(status === 'non_renewing') && (
                <div>
                    <button onClick={() => props.restartNonRenewingSubscription(id)} >Restart</button>
                </div>
            )}
        </div>

    )
}