import React, { useState, useEffect } from 'react';
import Routes from './routes/Routes';
import { Router} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/js/bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import history from './history';
import {Header} from './components/header/header';
// import LeftSidebar from './components/shared/left-sidebar';
import { UserProvider } from './contexts/user';
import Cookies from 'js-cookie';
import Axios from 'axios';

const logout = () => {
  Cookies.remove('user-token');
  window.location.href = '/login'
}

Axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (401 === error.response.status) {
        Cookies.remove('user-token');
        Cookies.set('login_return', window.location.href);
        window.location.href = '/login'
    } 
    return Promise.reject(error);
})

function App() {
  const userOnCookie = Cookies.get('user-token');

  const [user, setUser] = useState(userOnCookie);
  useEffect(() => {
    setUser(userOnCookie)
  }, [userOnCookie])

  return (
    <Router history={history}>
      <UserProvider value={{ user, setUser }}>
        <div className="page-wrapper">
          { user &&  <Header logout={logout} /> }
          <div className="page-body-wrapper">
            <Routes userContext={user} />
          </div>
        </div>
      </UserProvider>
    </Router>
  );
}


export default App;
