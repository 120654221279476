import React, { Component } from 'react';
import Select from 'react-select'
import ReactFlagsSelect from 'react-flags-select';
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import {SubscriptionItemPrice} from '../../components/subscription-item/subscription-item-price'
import './select-plan-pricing.scss';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

class SelectPlanPricing extends Component {

    constructor(props) {
        super(props)

        const query_params = new URLSearchParams(window.location.search);
        if (query_params.get('returnUrl')) {
            Cookies.set('app-redirect-url', query_params.get('returnUrl'));
        }

        this.state = {
            plans_loading: false,
            selected_item_price: null,
            item_prices: [],
            selected_item: this.props.match.params.planId,
            accountId:query_params.get('accountId'),
            source:query_params.get('source'),

            country_code: "",
            cycle: "",
            currency_code: "",

            redirect: "",
        }

        this.onCurrencyChange = this.onCurrencyChange.bind(this);
        this.onCycleChange = this.onCycleChange.bind(this);
        this.getItemPrices = this.getItemPrices.bind(this);
        this.onItemPriceSelect = this.onItemPriceSelect.bind(this);
    }

    componentDidMount() {

        // const query_params = new URLSearchParams(window.location.search);
        // if (query_params.get('returnUrl') && Cookies.get('app-redirect-url')) {
        //     this.setState({ redirect: window.location.pathname})
        // }

        if (!this.state.selected_item) {
            this.setState({redirect: "/subscriptions/select-plan"});
            return;
        }

        this.getItemPrices();
    }

    getItemPrices() {
        this.setState({plans_loading:true}, () => {
            request('GET', '/subscriptions/items/'+this.state.selected_item, null, true).then(res => {
                this.setState({
                    plans_loading: false,
                    item_prices: res.data,
                })
            }).catch(err => {
                if (err.response.status_code === 404) {
                    Swal.fire({title: "Error", message:err.response.data.message, type:"error", timer:5000}).then(() => {
                        this.setState({redirect: "/subscriptions/select-plan"});
                    })
                } else {
                    Swal.fire({title: "Error", message:"Something has gone wrong, available subscriptions are not available. Please try again later", type:"error", timer:5000}).then(() => {
                        this.setState({redirect: "/subscriptions/select-plan"});
                    })
                }
            })
        });
    }

    onCurrencyChange = e => {
        let currencyCode = "AUD";
        switch (e) {
            case "AU":
                currencyCode = "AUD"
                break;
            case "US":
                currencyCode = "USD"
                break;
            case "GB":
                currencyCode = "GBP"
                break;
            default:
                currencyCode = "AUD";
                break;
        }
        this.setState({country_code:e, currency_code: currencyCode});
    }

    onCycleChange = e => {
        this.setState({cycle: e.value});
    }


    onItemPriceSelect(planId) {
        request('POST', '/subscriptions/start-subscription', {plan_id: planId, source:this.state.source, accountId:this.state.accountId}, true).then(res => {
            //Cookies.set('app-redirect-url', res.data.redirectURL);
            window.location.href = res.data.url;
        })
        .catch(error => {
            this.setState({isLoading: false});
            Swal.fire(
                'Error',
                error.response.data.error,
                'error'
            )
        });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }

        const cycleOptions = [{value: 'month', label: "Monthly"}, {value: "year", label: "Annual"}];
        let items = null;
        if (this.state.item_prices) {
            items = this.state.item_prices.map(item => {
                if ( (!this.state.currency_code || (this.state.currency_code === item.currency)) && (!this.state.cycle || (this.state.cycle === item.cycle)) ){
                    return <SubscriptionItemPrice item={item} onSelect={this.onItemPriceSelect} />
                }
                return null;
            })
        }

        //TODO: Add loading gif

        return ( 
            
            <div className="login-container text-center">
                <div className="container">
                    <div className="row select-pricing-row mt-5">
                        {/* <div className="col-6"> */}
                        <label className="my-auto">Currency:</label>
                            <ReactFlagsSelect 
                                countries={["AU", "US", "GB"]}
                                customLabels={{"US": "USD","GB": "GBP","AU": "AUD"}} 
                                placeholder="Select currency"  
                                searchable={true} 
                                onSelect={this.onCurrencyChange} 
                                selected={this.state.country_code} 
                            />
                            {/* </div> */}
                        {/* <div className="col-6"> */}

                        <label className="my-auto">Billing Cycle</label>
                        <div styles={{padding: '5px 10px'}}>
                            <Select 
                                placeholder="Select billing cycle"
                                options={cycleOptions} 
                                selected={this.state.cycle} 
                                onChange={this.onCycleChange}
                            />
                            </div>
                    </div>
                        {/* {this.state.plans_loading &&  <p>Loading plans...</p>} */}
                        {/* <div style={{display: 'flex', flexDirection: 'row'}} > */}
                        <div className="row mt-3">
                            {!this.state.plans_loading && items}
                        </div>
                </div>
                        {/* </div> */}
            </div>
        );
    }
}

export default SelectPlanPricing;