import React, { Component } from 'react';
import UserContext from '../contexts/user';
import { Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';


class ProtectedRoute extends Component {
    static contextType = UserContext;
    

    render() {
        if (!this.context.user) {
            
            Cookies.set('login_return', window.location.href);
            
            return <Redirect push to={'/login'} />
        } else {
            return <Route {...this.props}></Route>
        }
    }
}

export default ProtectedRoute;