import React, { Component } from 'react'
import Swal from 'sweetalert2';
import { request } from '../../constants/constants';
import {SubscriptionList} from '../../components/subscription-list/subscription-list'


class dashboard extends Component {

    constructor(props) {
        super(props)

        this.state = {
            subscriptions: [],
            subscriptions_loading: false,
        }
    }

    componentDidMount() {
        this.getActiveSubscriptions();
    }

    getActiveSubscriptions() {
        this.setState({ subscriptions_loading: true }, () => {
            request('GET', '/subscriptions?status=active', null, true).then(res => {
                this.setState({
                    subscriptions_loading: false,
                    subscriptions: res.data.subscriptions,
                })
            }).catch(err => {
                Swal.fire(
                    'Error',
                    "Something has gone wrong, available subscriptions are not available. Please try again later",
                    'error'
                )
            })
        });
    }

    render() {

        if (this.state.subscriptions_loading) {
            return (
                <div>
                    Is Loading...
                </div>
            )
        }

        if (!this.state.subscriptions) {
            return (
                <div>
                    No active subscriptions yet
                </div>
            )
        }

        return (
            <div>
                <SubscriptionList subscriptions={this.state.subscriptions} />
            </div>
        )

        // return (
        //     <div className="container">
        //        <div className="row mt-3">
        //           <span className="m-2"> Active Subscriptions </span>
        //             <div className="overflow-auto" style={{height: '280px'}}>
        //                 <div class="card mt-1">
        //                     <div class="d-flex justify-content-between">
        //                         <div class="p-4">Integration Name</div>
        //                         <div class="p-4">Monthly Price</div>
        //                         <div class="p-4">Active Since</div>
        //                         <div class="p-4">
        //                             <button type="button" class="btn btn-primary mx-1">Manage</button>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div class="card mt-1">
        //                     <div class="d-flex justify-content-between">
        //                         <div class="p-4">Integration Name</div>
        //                         <div class="p-4">Monthly Price</div>
        //                         <div class="p-4">Active Since</div>
        //                         <div class="p-4">
        //                             <button type="button" class="btn btn-primary mx-1">Manage</button>
        //                         </div>
        //                     </div>
        //                 </div>
        //                 <div class="card mt-1">
        //                     <div class="d-flex justify-content-between">
        //                         <div class="p-4">Integration Name</div>
        //                         <div class="p-4">Monthly Price</div>
        //                         <div class="p-4">Active Since</div>
        //                         <div class="p-4">
        //                             <button type="button" class="btn btn-primary mx-1">Manage</button>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //        </div>
        //     </div>
        // )
    }
}

export default dashboard;
