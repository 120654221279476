import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import Login from '../pages/login/login';
import Register from '../pages/register/register'
import UserContext from '../contexts/user';
import SelectPlan from '../pages/select-plan/select-plan';
import SelectPlanPricing from '../pages/select-plan/select-plan-pricing';
import CheckoutSuccess from '../pages/checkout-success/checkout-success';
import invoices from '../pages/invoices/invoices';
import dashboard from '../pages/dashboard/dashboard';
import myaccount from '../pages/myaccount/myaccount';
import Subscriptions from '../pages/subscriptions/subscriptions'
import SubscriptionDetail from '../pages/subscriptions/subscription-detail'

function Routes() {
    return (
        <Switch>
            
            <Redirect exact from="/" to="/dashboard" />

            <ProtectedRoute exact path="/dashboard" component={dashboard}/>
            <ProtectedRoute exact path="/subscriptions" component={Subscriptions} />
            <ProtectedRoute exact path="/subscriptions/select-plan/:planId" component={SelectPlanPricing} />
            <ProtectedRoute exact path="/subscriptions/select-plan" component={SelectPlan} />
            <ProtectedRoute exact path="/subscriptions/:subscriptionId" component={SubscriptionDetail}/>
            <ProtectedRoute exact path="/checkout/callback" component={CheckoutSuccess} />
            <ProtectedRoute exact path="/invoices" component={invoices}/>
            <ProtectedRoute exact path="/invoices/:invoiceId" />
            <ProtectedRoute exact path="/myaccount" component={myaccount}/>

            <UserContext.Consumer>
                {({user, setUser}) => (
                    <>
                        <Route path="/login" exact component={() => <Login onLogin={setUser} />}  />
                        <Route path="/signup" exact component={() => <Register onRegister={setUser} />}  />
                    </>
                )}

            </UserContext.Consumer>


        </Switch>
    );
}

export default Routes;